import React, { FC, useEffect, useState, useRef, forwardRef } from 'react';
import classNames from 'classnames';
import { Member } from '@wix/members-domain-ts';
import { Tooltip } from 'wix-ui-tpa';

import { InjectedClassNameProps } from '../../../types';
import isOverflowing from '../../../services/is-overflowing';
import styles from './MemberName.scss';
import {
  DEFAULT_HIDE_DELAY,
  DEFAULT_SHOW_DELAY,
} from '../../../constants/tooltip';

export interface MemberNameProps extends InjectedClassNameProps {
  member?: Member;
  name: string;
  tooltipClass?: string;
  goToMemberPage?: Function;
}

interface NameProps {
  className?: string;
  name: string;
}

interface NameWithTooltipProps extends NameProps {
  tooltipClass?: string;
}

const Name = forwardRef<HTMLSpanElement, NameProps>(
  ({ name, className }, ref) => (
    <span ref={ref} className={classNames(className, styles.nameButton)}>
      {name}
    </span>
  ),
);

const NameWithTooltip = forwardRef<HTMLSpanElement, NameWithTooltipProps>(
  ({ tooltipClass, name, className }, ref) => {
    return (
      <Tooltip
        content={name}
        className={tooltipClass}
        showDelay={DEFAULT_SHOW_DELAY}
        hideDelay={DEFAULT_HIDE_DELAY}
      >
        <Name name={name} className={className} ref={ref} />
      </Tooltip>
    );
  },
);

const MemberName: FC<MemberNameProps> = ({ name, className, tooltipClass }) => {
  const [isNameTooltipVisible, setNameTooltipVisible] = useState(false);
  const nameWrapperRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const isNameOverflowing = nameWrapperRef.current
      ? isOverflowing(nameWrapperRef.current)
      : false;

    if (isNameOverflowing !== isNameTooltipVisible) {
      setNameTooltipVisible(isNameOverflowing);
    }
  }, [isNameTooltipVisible, name]);

  return isNameTooltipVisible ? (
    <NameWithTooltip
      name={name}
      className={className}
      ref={nameWrapperRef}
      tooltipClass={tooltipClass}
    />
  ) : (
    <Name name={name} className={className} ref={nameWrapperRef} />
  );
};

export default MemberName;
